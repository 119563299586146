import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { assign as windowLocationAssign } from 'common/window_location';

import { renderAlerts, createCoreAuthorizeUri } from '../Util';
import { Options, Alert, SsoConnection } from '../types';
import SignIn from './SignIn';
import ChooseConnection from './ChooseConnection/ChooseConnection';

import './signin.scss';

export const classNameScope = 'frontend--authentication--components--signin';

interface Props {
  options: Options;
}

interface State {
  renderLoginForm: boolean;
  alerts: Array<[Alert['level'], Alert['message']]>;
}

interface OnLoginError {
  (level: Alert['level'], message: Alert['message']): void;
}

class SignInContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { baseDomainUri } = this.props.options;

    this.state = {
      renderLoginForm: false,
      alerts: []
    };
  }

  onConnectionChosen = (connection: SsoConnection) => {
    const { baseDomainUri } = this.props.options;

    // Redirect to core to initialize the Auth0 login
    windowLocationAssign(createCoreAuthorizeUri(baseDomainUri, connection));
  };

  onLoginStart = () => {
    // clear out alerts on login start
    this.setState({ alerts: [] });
  };

  onLoginError: OnLoginError = (level, message) => {
    this.setState({ alerts: [[level, message]] });
  };

  setLoginFormVisibility = (show: boolean) => {
    this.setState({ renderLoginForm: show });
  };

  renderChooseConnectionOrSignInForm = () => {
    const { options } = this.props;
    const { renderLoginForm } = this.state;

    // if "auth0_connections" is set in the site config, we show a list
    // of buttons to choose a connection
    if (
      options.ssoConnections?.some((connection) => connection.displayOnLogin) &&
      renderLoginForm === false
    ) {
      return (
        <ChooseConnection
          options={options}
          onConnectionChosen={this.onConnectionChosen}
          setLoginFormVisibility={this.setLoginFormVisibility}
        />
      );
    } else {
      return (
        // either there aren't any specific connections set up,
        // or the "Sign in with a Socrata ID" button was clicked
        <SignIn
          onLoginStart={this.onLoginStart}
          onLoginError={this.onLoginError}
          options={options}
          setLoginFormVisibility={this.setLoginFormVisibility}
        />
      );
    }
  };

  renderFormMessage = () => {
    const { formMessage, connections } = this.props.options;
    const renderingLoginForm = isEmpty(connections) || this.state.renderLoginForm === true;

    if (renderingLoginForm && !isEmpty(formMessage)) {
      return <div className="signin-form-message" dangerouslySetInnerHTML={{ __html: formMessage! }} />;
    }
  };

  renderBackButton = () => {
    const { options } = this.props;
    const { renderLoginForm } = this.state;

    // only show the button to go back to "choose connection" if we're told to
    if (!isEmpty(options.ssoConnections) && renderLoginForm === true) {
      return (
        <a
          className={`signin-button-back-to-options ${classNameScope}--back-to-options`}
          onClick={() => this.setLoginFormVisibility(false)}
        >
          <span className={`${classNameScope}--back-to-options-icon`}>
            <SocrataIcon name={IconName.ArrowLeft} />
            <div dangerouslySetInnerHTML={{ __html: I18n.t('screens.sign_in.back_to_sign_in_selection') }} />
          </span>
        </a>
      );
    }
  };

  render() {
    const { alerts } = this.state;
    const { options } = this.props;
    const { flashes } = options;

    return (
      <div className={`${classNameScope}--container`}>
        {this.renderBackButton()}
        {/*
        // @ts-ignore */}
        {renderAlerts(concat(flashes, alerts!))}
        <div className={`${classNameScope}--header-container`}>
          <h1 className={`h1 ${classNameScope}--header`}>
            {I18n.t('screens.sign_in.headline', { site: options.companyName })}
          </h1>
          {this.renderFormMessage()}
        </div>
        {this.renderChooseConnectionOrSignInForm()}
      </div>
    );
  }
}

export default SignInContainer;
